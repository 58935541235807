
export const swiperTestimonios = () => {
    new Swiper(".swiper-testimonios", {
                
        slidesPerView: 1,
        spaceBetween: 16,
        loop: true,
        autoplay: true,
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
            }
        }
    }); 
};
